import { useAuthStore } from "~~/store/auth";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const authStore = useAuthStore();
  if (to.path !== "/login") {
    const isAuthenticated = await authStore.isAuthenticated();
    const refreshToken = authStore.getRefreshToken();
    if (!isAuthenticated && !refreshToken) {
      return navigateTo({
        path: "/login",
        query: { redirectUrl: to.fullPath },
      });
    }
    if (refreshToken) {
      await authStore.verifyAuthenticationAndRefreshTokens();
    }
  }
});
